import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ContentContainer from '../ContentContainer';
import UserMdIcon from '../../icons/UserMdIcon';
import { useTheme } from '@mui/system';
import MedkitIcon from '../../icons/MedkitIcon';
import FlaskIcon from '../../icons/FlaskIcon';

import { gridRow, gridRowMarginTop, gridRowMarginBottom } from './style';
import CubesIcon from '../../icons/CubesIcon';
import CommentsIcon from '../../icons/CommentsIcon';
import CheckSquareIcon from '../../icons/CheckSquareIcon';

const ServiceIconBox = ({ icon, name, description, linkPath = null }) => {
  const NameBox = () => (
    <Box component='h3' sx={{ margin: 0, color: 'primary.main' }}>
      {name}
    </Box>
  );

  return (
    <Box
      component='div'
      sx={{
        width: '330px',
        display: 'flex',
      }}
    >
      <Box
        component='div'
        sx={{
          width: '36px',
          height: 1/1,
          '& svg': {
            width: '36px',
            height: '36px',
          }
        }}
      >
        {icon}
      </Box>
      <Box
        component='div'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginTop: '10px',
          paddingLeft: '15px',
        }}
      >
        {linkPath ? (
          <Link to={linkPath} style={{ textDecoration: 'none'}}>
            <NameBox />
          </Link>
        ) : (
          <NameBox />
        )}
        <Box component='p' sx={{ typography: 'subtitle1', color:'grey.600'}}>
          {description}
        </Box>
      </Box>
    </Box>
  )
};

export default () => {
  const theme = useTheme();
  return (
    <Box component='div' sx={{
      width: 1/1,
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
    }}>
      <ContentContainer wrapperSX={{...gridRow, ...gridRowMarginTop }} >
        <ServiceIconBox
          icon={<UserMdIcon color={theme.palette.primary.main} />}
          name={'Consulting'}
          description={'We want to help your practice achieve clinical and financial success. That’s where we come in.'}
          linkPath='/consulting'
        />
        <ServiceIconBox
          icon={<MedkitIcon color={theme.palette.primary.main} />}
          name={'Point of Care Drug Dispensing'}
          description={'Point-of-Care Drug Dispensing makes it easier for you and your patients to access the prescriptions they need.'}
          linkPath='/consulting?elemID=point-of-care-drug-dispensing'
        />
        <ServiceIconBox
          icon={<FlaskIcon color={theme.palette.primary.main} />}
          name={'Toxicology'}
          description={'We enable doctors to monitor patients and reduce liability. By providing key information we make it easier than ever to better manage the treatment of your patients.'}
          linkPath='/services?elemID=toxicology'
        />
      </ContentContainer>
      <ContentContainer wrapperSX={{...gridRow, ...gridRowMarginBottom}} >
        <ServiceIconBox
          icon={<CubesIcon color={theme.palette.primary.main} />}
          name={'Pharmaceutical Compounds'}
          description={'We provide a better way to meet the unique needs of individual patients.'}
          linkPath='/services?elemID=pharmaceutical-compounds'
        />
        <ServiceIconBox
          icon={<CheckSquareIcon color={theme.palette.primary.main} />}
          name={'Billing Solutions'}
          description={'Equipped with comprehensive knowledge of the healthcare billing and medical billing realms, the only resource that you’ll need to resolve any issues you might be experiencing with your practice.'}
          linkPath='/billing_solutions'
        />
        <ServiceIconBox
          icon={<CommentsIcon color={theme.palette.primary.main} />}
          name={'Training'}
          description={'Whether you want a full procedural overhaul or a refresher course we offer the personalized training your practice needs.'}
          linkPath='/training'
        />
      </ContentContainer>
    </Box>
  )
}