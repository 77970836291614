import logo from './logo.svg';
import { useEffect, useMemo } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/system';

import Home from './routes/Home';
import Services from './routes/Services';
import Consulting from './routes/Consulting';
import BillingSolutions from './routes/BillingSolutions';
import About from './routes/About';
import ContactUs from './routes/ContactUs';

import theme from './theme/theme';

// import { createTheme } from '@mui/material;
import createTheme from '@mui/material/styles/createTheme';

function useQuery(){
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const ScrollManager = () => {
  const { pathname } = useLocation();
  const query = useQuery();

  useEffect(() => {
    const hasId = query.get('elemID');
    const idElem = hasId ? document.getElementById(hasId) : null;

    if(idElem) idElem.scrollIntoView();
    else window.scrollTo(0, 0);

  }, [pathname]);

  return null;
};

function App() {
  const defaultTheme = createTheme();
  const appTheme = createTheme(defaultTheme, theme);

  return (
    <ThemeProvider theme={appTheme} >
      <Router>
        <ScrollManager />
        <Switch>
          <Route path='/' exact={true} >
            <Home />
          </Route>
          <Route path='/about'>
            <About />
          </Route>
          <Route path='/consulting' >
            <Consulting />
          </Route>
          <Route path='/services' >
            <Services/>
          </Route>
          <Route path='/billing_solutions' >
            <BillingSolutions/>
          </Route>
          <Route to='/contact'>
            <ContactUs />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
