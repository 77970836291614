import React from 'react';
import logo from '../assets/img/logo.png'

import Box from '@mui/material/Box';

export default () => {
  return (
    <Box
      component='span'
      sx={{
        display: 'block',
        '> img': {
          padding: '15px 0px',
        }
      }}
    >
      <img src={logo} alt="MyMdSoln" width='300px'/>
    </Box>
  )
};