const home = {
  bgcolor: 'common.white',
  width: 1/1,
  height: 1/1,
};

const bannerImage = {
  width: 1/1,
  height: '550px',
  display: 'block',
  '& img': {
    width: 1/1,
    height: 1/1,
    objectFit: 'cover',
    objectPosition: 'top',
  }
};

module.exports = {
  home,
  bannerImage,
};