import { createTheme } from '@mui/system';

const theme = createTheme({
  breakpoints: {
    keys: [],
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    }
  },
  palette: {
    mode: 'light',
    common: {
      black: '#000',
      white: '#FFF',
    },
    primary: {
      main: '#072350',
      light: '#0a3380',
      dark: '#071d3a',
      contrastText: '#e0e0e0',
    },
    secondary: {
      main: '#212121',
      light: '#292929',
      dark: '#1f1f1f',
      contrastText: '#979797',
    },
    error: {
      main: '#9c161b',
      light: '#bf161b',
      dark: '#86181d',
      contrastText: '#e0e0e0',
    },
    warning: {
      main: '#ce9e14',
      light: '#edbd14',
      dark: '#bf8e17',
      contrastText: '#e0e0e0',
    },
    info: {
      main: '#0c5fa3',
      light: '#0c82c7',
      dark: '#0c5390',
      contrastText: '#e0e0e0',
    },
    success: {
      main: '#089517',
      light: '#08b016',
      dark: '#087817',
      contrastText: '#e0e0e0',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#f5f5f5',
      A200: '#eeeeee',
      A400: '#bdbdbd',
      A700: '#616161',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: '#173A5E',
      secondary: '#46505A',
      disabled: '#939395',
    },
    divider: 'rgba(255,255,255,0.12)',
    background: {
      paper: 'rgba(255,255,255,0.9)',
      default: '#fff',
    },
  },
  shape: {
    borderRadius: 1
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: "6rem",
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h2:{
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: "3.75rem",
      lineHeight: 1.2,
      letterSpacing: '-0.00833em',
    },
    h3:{
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "3rem",
      lineHeight: 1.167,
      letterSpacing: '0em',
    },
    h4:{
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "2.125rem",
      lineHeight: 1.235,
      letterSpacing: '0.00735em',
    },
    h5:{
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "61.5rem",
      lineHeight: 1.334,
      letterSpacing: '0em',
    },
    h6:{
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: "1.25rem",
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
    subtitle1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
    },
    subtitle2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
    },
    body1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    button: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
      fontSize: "0.875rem",
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      // textTransform: 'uppercase'
    },
    caption: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
    },
    overline: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
    },
  }
});

export default theme;