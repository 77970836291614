const gridRow = {
  display: 'flex',
  justifyContent: 'space-between',
};

const gridRowMarginTop = {
  marginTop: '80px'
};

const gridRowMarginBottom = {
  marginBottom: '80px'
};

module.exports = {
  gridRow,
  gridRowMarginTop,
  gridRowMarginBottom,
};