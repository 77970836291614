import React from 'react';
import { styled } from '@mui/system';

export default styled('button')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: "15px 80px",
  border: 'none',
  ...theme.typography.button,
  cursor: 'pointer',
  ':hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  ':active': {
    backgroundColor: theme.palette.primary.light,
    border: `2px ${theme.palette.primary.main} inset`,
    padding: "13px 78px",
  }
}));