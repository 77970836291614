import React from 'react';
import { styled } from '@mui/system';

export default styled('input')(({ theme }) => ({
  fontFamily: theme.typography.body1.fontFamily,
  fontWeight: theme.typography.body1.fontWeight,
  fontSize: theme.typography.body1.fontSize,
  lineHeight: theme.typography.body1.lineHeight,
  letterSpacing: theme.typography.body1.letterSpacing,
  border: `1px solid ${theme.palette.grey['300']}`,
  padding: '10px 10px',
}));