import React from 'react';
import Box from '@mui/material/Box';
import SiteHeader from '../../components/layouts/SiteHeader';
import SiteFooter from '../../components/layouts/SiteFooter';
import ContentContainer from '../../components/layouts/ContentContainer';
import {bannerImage} from '../Home/styles';
import banner from '../../assets/img/banner_1366x512.png';

export default (props) => {
  const about = {
    bgcolor: 'common.white',
    width: 1/1,
    height: 1/1,
  };
  const containerPaddingSX={
    padding: '50px 0px',
  };
  const headerSX = { color: 'primary.main' };
  const textSX = { typography: 'subtitle1', color:'grey.600' };
  const containeWrappingSX = {
    '& > p': textSX,
    '& > ul': textSX,
    '& > h2': headerSX,
    '& > h3': headerSX,
    '& > h4': headerSX,
  };
  const containerGreySX = {backgroundColor: 'grey.100'};

  return (
    <Box
      component='div'
      sx={about}
    >
      <SiteHeader />

      <Box component='span' sx={bannerImage} >
        <img src={banner} alt="Happy Doctors" />
      </Box>

      <ContentContainer containerSX={{...containerPaddingSX, ...containerGreySX}} wrapperSX={containeWrappingSX} >
        <Box component='p' >Founded by Dr. Mo Sweeney, My MD Solutions equips physicians and their practices with the tools and knowledge they need to provide superior patient care and achieve maximum profitability nationwide. With experience in practice management software, HIPAA compliance, and medical billing analysis, My MD Solutions provides the strong leadership and coordination necessary to facilitate a complete transformation of your practice’s financial and operational processes.</Box>
        <Box component='p' >As one of Atlanta’s most experienced medical consulting firms, My MD Solutions believes that a successful practice is a patient-centered practice. We are proud to offer services that benefit both patients and physicians. Our team of expert medical consultants offers a variety of practical and proven solutions for both the physicians and its clinic.</Box>
        <Box component='h4' >Unlock Your Clinic’s Maximum Potential</Box>
        <Box component='p' >My MD Solutions provides each practice with a comprehensive medical management solution using our proven process. We will guide our physicians and practice administrators through each step, while never losing focus of the clinic’s long-term financial goals. At the start of each business relationship, our team of medical consultants conducts a thorough practice analysis. This free examination allows us to see where your practice is losing money, and develop a plan to recover more revenue with your existing patient load.</Box>
        <Box component='p' >Our all-inclusive medical management solution saves physicians and practices both time and money by adding additional services at no charge to the physician or their clinic. By doing this it allows the physician to spend more time with their patients and participate in our clinical case studies, benefitting both the clinic and patients.</Box>
      </ContentContainer>

      <SiteFooter/>
    </Box>
  );
};