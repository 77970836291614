import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Input, TextArea, Button } from '../inputs';

export default ({ formHeader = 'Contact Us'}) => {
  const [email, setEmail] = useState('');
  const [subject, setSubect] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    //todo: hook in STATE API call here.
    window.location.href = `mailto:contact@mymdsoln.com?subject=${encodeURIComponent(subject)}&message=${encodeURIComponent(message)}`;
  };

  return (
    <Box component='div' sx={{
      '& input, textarea': {
        margin: '10px 0px',
        '& :focus': {
          borderRadius: '0px !important',
        }
      },
      '& button': {
        marginTop: '10px',
      }
    }} >
      <Box
        component='h2'
        sx={{
          textAlign: 'left',
          color: 'primary.main'
        }}
      >{formHeader}</Box>
      <form onSubmit={handleSubmit} >
        <Input
          placeholder='* Your Email'
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ width: 1/1 }}
        />

        <Input
          placeholder='* Subject'
          value={subject}
          required
          onChange={(e) => setSubect(e.target.value)}
          sx={{ width: 1/1 }}
        />

        <TextArea
          placeholder='* Your Message'
          rows={8}
          // col={10}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          sx={{ width: 1/1 }}
        />

        <Button type='submit'>Send Us Your Message</Button>
      </form>
    </Box>
  )
};