import React from 'react';
import { contentContainer, contentWrapper } from './styles';

import Box from '@mui/material/Box';

export default ({ children, containerSX = {}, wrapperSX = {}, ...rest }) => {
  return (
    <Box
      component='div'
      sx={{...contentContainer, ...containerSX}}
      {...rest}
    >
      <Box
        component='div'
        sx={{...contentWrapper, ...wrapperSX}}
      >
        {children}
      </Box>
    </Box>
  );
}