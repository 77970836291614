import React from 'react';
import {Box} from '@mui/system';

import ContentContainer from '../../components/layouts/ContentContainer';
import ContactForm from '../../components/forms/ContactForm';
import SiteHeader from '../../components/layouts/SiteHeader';
import SiteFooter from '../../components/layouts/SiteFooter';

export default () => {
  return (
    <Box component='div' sx={{ bgcolor: 'common.white', width: 1/1 }}>
      <SiteHeader />

      <ContentContainer containerSX={{ padding: '20px 0px' }} >
        <Box component='h4' sx={{}}>Learn More About My MD Solutions</Box>
        <Box component='p' sx={{ typography: 'subtitle1', color: 'grey.600' }}>Based in Atlanta, Georgia, My MD Solutions provides customized medical management solutions for each of our clients.</Box>
        <Box component='h5'>To schedule an initial consultation, call (678) 677-7367 or fill out our online contact form.</Box>
      </ContentContainer>

      <ContentContainer>
        <hr/>
      </ContentContainer>

      <ContentContainer containerSX={{ padding: '20px 0px 40px 0px', textAlign: 'center'}}>
        <ContactForm formHeader='Contact us today for your free private initial consultation' />
        <Box component='h5'> You may also email us directly at: contact@mymdsoln.com</Box>
      </ContentContainer>

      <SiteFooter />
    </Box>
  )
}