import React from 'react';
import Box from '@mui/material/Box';
import SiteHeader from '../../components/layouts/SiteHeader';
import SiteFooter from '../../components/layouts/SiteFooter';
import ContentContainer from '../../components/layouts/ContentContainer';
import {bannerImage} from '../Home/styles';
import banner from '../../assets/img/banner_1366x512.png';

export default (props) => {
  const consulting = {
    bgcolor: 'common.white',
    width: 1/1,
    height: 1/1,
  };
  const containerPaddingSX={
    padding: '50px 0px',
  };
  const headerSX = { color: 'primary.main' };
  const textSX = { typography: 'subtitle1', color:'grey.600' };
  const containeWrappingSX = {
    '& > p': textSX,
    '& > ul': textSX,
    '& > h2': headerSX,
    '& > h3': headerSX,
    '& > h4': headerSX,
  };
  const containerGreySX = {backgroundColor: 'grey.100'};
  // const textSX = { typography: 'subtitle1', color:'grey.600'};

  return (
    <Box
      component='div'
      sx={consulting}
    >
      <SiteHeader />

      <ContentContainer containerSX={{...containerPaddingSX, ...containerGreySX}} wrapperSX={containeWrappingSX}>
        <Box component='h2' >Consulting</Box>
        <Box component='p' >We want to help your practice achieve clinical and financial success. A successful business model starts with good patient care, but many practice still find themselves with an unstable financial foundation. That’s where we come in.</Box>
        <Box component='p' >Our suite of financial and clinical services include:</Box>
        <Box component='ul' >
          <Box component='li' >Outsourced Medical Billing</Box>
          <Box component='li' >Revenue Cycle Management</Box>
          <Box component='li' >HIPPA Compliance</Box>
          <Box component='li' >ICD-10 Compliance and Training</Box>
          <Box component='li' >Population Health Management</Box>
          <Box component='li' >Workflow Management</Box>
        </Box>
      </ContentContainer>

      <ContentContainer containerSX={containerPaddingSX} wrapperSX={containeWrappingSX} id='point-of-care-drug-dispensing'>
        <Box component='h3' sx={{ textAlign: 'center'}} >Other Consulting Services Include</Box>
        <Box component='hr' sx={{ marginBottom: '50px' }} />
        <Box component='h3' >Point of Care Drug Dispensing</Box>
        <Box component='p' >Point-of-Care Drug Dispensing makes it easier for you and your patients to access the prescriptions they need. By cutting out the trip to the pharmacy you are saving your patients time and stress and saving your practice money. Point-of-Care Drug Dispensing also ensures that your patients are filling their prescriptions in a timely fashion and leads to better follow-up care.</Box>
      </ContentContainer>

      <SiteFooter/>

    </Box>
  );

}