import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import ContentContainer from '../ContentContainer';

export default () => {
  const noStyle = { textDecoration: 'none' };

  return (
    <Box component='div' sx={{
      bgcolor: 'secondary.main',
      color: 'secondary.contrastText',
      padding: '40px'
    }}>
      <ContentContainer>
        <Box component='div' sx={{ display: 'flex', alignItems:'center', justifyContent:'space-between' }} >
          <Box component='div'>
            <Box component='span'>© 2014, MyMDSolutions™ - All Rights Reserved.</Box>
          </Box>
          <Box component='div' sx={{
            '& span': {
              margin: '0px 10px',
              color: 'secondary.contrastText',
              ':hover': {
                color: 'primary.light'
              }
            }
          }}>
            <Link to='/' style={noStyle}>
              <Box component='span'>Home</Box>
            </Link>
            <Link to='/about' style={noStyle}>
              <Box component='span'>About</Box>
            </Link>
            <Link to='/services' style={noStyle}>
              <Box component='span'>Services</Box>
            </Link>
            <Link to='/billing_solutions' style={noStyle}>
              <Box component='span'>Billing Solutions</Box>
            </Link>
            <Link to='/contact' style={noStyle}>
              <Box component='span'>Contact</Box>
            </Link>
          </Box>
        </Box>
      </ContentContainer>
    </Box>
  )
}