import React from 'react';
import Box from '@mui/material/Box';
import SiteHeader from '../../components/layouts/SiteHeader';
import SiteFooter from '../../components/layouts/SiteFooter';
import ContentContainer from '../../components/layouts/ContentContainer';

export default (props) => {
  const billingSolutions = {
    bgcolor: 'common.white',
    width: 1/1,
    height: 1/1,
  };
  const containerPaddingSX={
    padding: '50px 0px',
  };
  const headerSX = { color: 'primary.main' };
  const textSX = { typography: 'subtitle1', color:'grey.600' };
  const containeWrappingSX = {
    '& > p': textSX,
    '& > ul': textSX,
    '& > h2': headerSX,
    '& > h3': headerSX,
    '& > h4': headerSX,
    '& > h5': headerSX,
  };
  const containerGreySX = {backgroundColor: 'grey.100'};
  // const textSX = { typography: 'subtitle1', color:'grey.600'};

  return (
    <Box
      component='div'
      sx={billingSolutions}
    >
      <SiteHeader />

      <ContentContainer containerSX={{...containerPaddingSX, ...containerGreySX}} wrapperSX={containeWrappingSX}>
        <Box component='h2' >Billing Solutions</Box>
      </ContentContainer>

      <ContentContainer containerSX={containerPaddingSX} wrapperSX={containeWrappingSX}>
        <Box component='h4' >Experience the Difference of the our Billing Solutions</Box>
        <Box component='p' >Equipped with comprehensive knowledge of the healthcare billing and medical billing realms, My MD Solutions is the only resource that you’ll need to resolve any issues you might be experiencing with your practice. Our process is as follows:</Box>

        <Box component='h5' >Step 1: Implement Proper Medical Insurance Billing Software</Box>
        <Box component='p' >My MD Solutions is adaptive; we can work with the healthcare billing software that you already use in your practice. If you don’t have any software whatsoever, we provide you with a Practice Management Software free of charge. After establishing our medical billing services with your preexisting or new software, we are able to record EOBs daily, giving you immediate visibility.</Box>

        <Box component='h5' >Step 2: Connect to a Secure Web Based Platform</Box>
        <Box component='p' >We work with whatever software you have. If you don’t have any system, we communicate with you through web based practice management software, provided to you for FREE. This allows our professional medical billing specialists to access your account both safely and securely, reassuring you that all patients’ information is protected. It also to posts all payments and adjustments into your patient ledgers within 24 hours, including any relevant notes.</Box>

        <Box component='h5' >Step 3: Begin Your Medical Billing Collections</Box>
        <Box component='p' >After setting up the proper framework for your medical billing services, we can then have our medical billing specialists handle all of the medical insurance claims for you. Our medical billing specialists resolve, missing patient data and sends all necessary electronic attachments and narratives in order to minimize the chance of a claim being rejected. Our medical billers will appeal any denied claims in a manner that is timely and effective.</Box>

        <Box component='h5' >Step 4: Regular Reporting That Keeps You in the Know</Box>
        <Box component='p' >We believe in working not only for practices, but with practices. This means that we keep you informed of our progress for your medical insurance billing collections every step of the way. This information is delivered in regular email reports that track office collections and production ratios, including:</Box>
        <Box component='ul'>
          <Box component='li'>The number of healthcare billing claims that we have submitted daily.</Box>
          <Box component='li'>The number of medical insurance billing claims that we have appealed daily.</Box>
          <Box component='li'>The number of claim errors that we have been resolved.</Box>
          <Box component='li'>Daily collection, monthly collections, recap followed up with an executive report.</Box>
        </Box>
        <Box component='p'>Take control over your medical billing issues through the use of professional medical billing services that keep you in the know, while allowing you to focus on what truly matters: your practice.</Box>
      </ContentContainer>

      <ContentContainer containerSX={{...containerPaddingSX, ...containerGreySX}} wrapperSX={containeWrappingSX}>
        <Box component='h4' >Eliminate Your Medical Billing Issues</Box>
        <Box component='p'>When you take advantage of all of the medical billing services that My MD Solutions has to offer, you will never have to settle for collecting anything less than 100% of everything that you produce.</Box>
        <Box component='p'>We strive to exceed our client’s expectations, using the expertise of our medical billing specialists to reverse claims through professional appeals while collecting all of the compensation that you deserve from insurance companies. With industry-leading tactics and guidance, you can take confidence in our ability to render all necessary medical billing services to lead to the most favorable outcome for your practice.</Box>
      </ContentContainer>

      <ContentContainer containerSX={containerPaddingSX} wrapperSX={containeWrappingSX}>
        <Box component='h4' >EOBs Posted Daily</Box>
        <Box component='p'>Our physicians billing services include posting all insurance payments and insurance contract adjustments to patient ledgers no more than 24 hours following the EOBs being scanned in to the system. Additionally, our team emails your staff daily in order to maintain the accuracy of the work on both ends. My Medical Billing recommends depositing checks the next day when possible, in order to further guarantee that our daily deposits are reflective of what is posted daily.</Box>
      </ContentContainer>

      <ContentContainer containerSX={{...containerPaddingSX, ...containerGreySX}} wrapperSX={containeWrappingSX}>
        <Box component='h4' >Daily Submissions of Primary and Secondary Claims</Box>
        <Box component='p'>Our medical billers electronically disperse primary and secondary claims daily through your current system. However, if your business doesn’t currently have an electronic system in place, our experts will establish one free of charge. When requested, our medical billing service also covers pre-authorizations.</Box>
        <Box component='p'>Additionally, using My MD Solutions circumvents the common clerical errors that can occur up to four times a week in some medical practices. This leads to the denial of claims for trivial reasons, making the process complicated and longer than it should be. Avoid these problems forever with the use of our healthcare billing expertise.</Box>
      </ContentContainer>

      <ContentContainer containerSX={containerPaddingSX} wrapperSX={containeWrappingSX}>
        <Box component='h4' >Accountability Reports Delivered Daily, Weekly and Monthly</Box>
        <Box component='p'>My MD Solutions is renowned for our constant communication with your office, keeping your management staff fully aware of our progress and of any issues that we may be experiencing that might potentially hinder our efficiency regarding healthcare billing collection.</Box>
      </ContentContainer>

      <ContentContainer containerSX={{...containerPaddingSX, ...containerGreySX}} wrapperSX={containeWrappingSX}>
        <Box component='h4' >Appealing Denied Claims</Box>
        <Box component='p'>Our medical billing company is different, primarily because we quickly and efficiently file and appeal all outstanding claims. If a claim is denied, we will address the cause of the problem immediately. This means that all claims are resolved in a timely manner, allowing you to collect the compensation that you deserve without having to wait.</Box>
      </ContentContainer>

      <ContentContainer containerSX={containerPaddingSX} wrapperSX={containeWrappingSX}>
        <Box component='h4' >Use of Electronic Attachments When Available</Box>
        <Box component='p'>When available, our medical billing specialists include all electronic attachments to claims. If your practice doesn’t currently use electronic attachments, our medical billing specialists will set one up for use with electronic claims at no additional charge.</Box>
        <Box component='p'>Should an insurance company not accept electronic attachments, we will process paper attachments in their place through the mail. We simply require that you reimburse postage.</Box>
      </ContentContainer>

      <ContentContainer containerSX={{...containerPaddingSX, ...containerGreySX}} wrapperSX={containeWrappingSX}>
        <Box component='h4' >Daily Focus on the Insurance Aging Report</Box>
        <Box component='p'>Our medical billing services include daily summaries regarding the Insurance Aging Report, which fluctuates according to how many overdue claims were appealed and how much money is being collected, among other factors. When you use our medical billing company, you are provided with this crucial information as well as facts related to current accounts receivable balances in different age ranges such as being 30 to 60 days overdue, 60 to 90 days overdue and past 90 days overdue. Should you want to exclude certain outstanding balances from our services, you can alert us via email in order to ensure that we are pursuing all of the accounts that you are actively looking to collect on.</Box>
      </ContentContainer>

      <ContentContainer containerSX={containerPaddingSX} wrapperSX={containeWrappingSX}>
        <Box component='h4' >Verify Procedures Unattached to Insurance</Box>
        <Box component='p'>Most procedures unattached to insurance are overlooked and ignored, meaning that they can go weeks without being remedied. Our medical billing services address these oversights as they surface, taking care of them as soon as possible.</Box>
        <Box component='p'></Box>
      </ContentContainer>

      <ContentContainer containerSX={{...containerPaddingSX, ...containerGreySX}} wrapperSX={containeWrappingSX}>
        <Box component='h4' >We Gather All Missing Information</Box>
        <Box component='p'>A common issue encountered by almost all medical practices is the dilemma of missing patient information. Unfortunately, this is often experienced up to three times a week by many medical offices, resulting in the denial of claims should this go unresolved for thirty (30) days. My MD Solutions guarantees the prevention of this problem, as our specialists will contact patients to obtain the relevant information needed to complete the file. We report our success through this medical billing service to you through our email reports, allowing you to track our various pathways of success to securing compensation for your healthcare billing impasses.</Box>
      </ContentContainer>

      <ContentContainer containerSX={containerPaddingSX} wrapperSX={containeWrappingSX}>
        <Box component='h4' >A Work Log Detailing Accounts Receivables</Box>
        <Box component='p'>The daily summary provided by our medical billing company acknowledges how many claims thirty (30) days or older have been acknowledged each and every day. Our medical billing specialists follow up on all of these overdue insurance balances on a weekly basis, allowing us to also detail information in our report regarding our contacts at various insurance companies as well. Your software will be updated every two to three weeks as well to include detailed notes from our expert medical billers to acknowledge claim status, as well as to provide any other relevant notes</Box>
      </ContentContainer>

      <ContentContainer containerSX={{...containerPaddingSX, ...containerGreySX}} wrapperSX={containeWrappingSX}>
        <Box component='h4' >My MD Solutions Service Summary</Box>
        <Box component='p'>The features below reflect the basic benefits of our medical billing services for your practice:</Box>
        <Box component='ul'>
          <Box component='li'>Accurate and timely posting of insurance payments and insurance contract adjustments to patient ledgers.</Box>
          <Box component='li'>Immediate appeal of all denied claims and closure of all paid claims.</Box>
          <Box component='li'>Implementation of proven narratives to properly appeal and collect on denied claims.</Box>
          <Box component='li'>Daily correction of procedures unattached to insurance claims.</Box>
          <Box component='li'>Daily processing and disbursement of primary and secondary claims.</Box>
          <Box component='li'>Daily processing and disbursement of primary and secondary claims.</Box>
          <Box component='li'>Preparation of electronic attachments when necessary.</Box>
          <Box component='li'>Thorough correction of missing patient information.</Box>
          <Box component='li'>Daily analysis and adjustment of the Insurance Aging Report.</Box>
          <Box component='li'>Daily, weekly and monthly reports delivered via email to update status of medical billing collection efforts.</Box>
          <Box component='li'>A mission dedicated to collecting all healthcare billing claims while eliminating all overdue balances over ninety (90) days old.</Box>
        </Box>
      </ContentContainer>

      <ContentContainer containerSX={containerPaddingSX} wrapperSX={containeWrappingSX}>
        <Box component='h4' >Insurance Billing Fees</Box>
        <Box component='p'>Regardless of whether you run a small practice or a large one, there is significant amount of constant work in sending claims, posting EOBs, appealing denied claims and managing accounts receivables .We can alleviate this burden from your practice, as we are solely dedicated to processing claims more accurately and quickly while appealing denied claims and reducing your accounts receivable. We charge a small percentage of your collections, which is billed to you on the first day of each month. Because we get paid when you do, our work is defined by the relentless pursuit of our team of specialists to get you the money that you deserve. You receive the checks from insurers directly; we do not receive any money from the insurers. Please contact us for a customized quote.</Box>
      </ContentContainer>

      <ContentContainer containerSX={{...containerPaddingSX, ...containerGreySX}} wrapperSX={containeWrappingSX}>
        <Box component='h4' >No Long Term Contracts – Cancel Anytime</Box>
        <Box component='p'>We do not have any long-term contracts. You can cancel at any time without a penalty. Of course, we are certain that we would increase your insurance collections and reduce your expenses, and you will stay with us. Begin experiencing the My MD Solutions difference in your practice, today.</Box>
      </ContentContainer>

      <ContentContainer containerSX={containerPaddingSX} wrapperSX={containeWrappingSX}>
        <Box component='h4' >Begin Our Services after Just 7 Days of Sign Up</Box>
        <Box component='p'>We ensure a smooth, thorough transition for your practice; within just seven (7) days, we will be integrating into your billing system, allowing us to handle all of your insurance claim submissions, patient billing and insurance inquiries. Additionally, we perform timely follow-ups to ensure that every claim is not only approved, but also paid on time. We can also handle your insurance verification and authorization for all scheduled appointments to improve patient satisfaction, making your practice more profitable.</Box>
      </ContentContainer>

      <ContentContainer containerSX={{...containerPaddingSX, ...containerGreySX}} wrapperSX={containeWrappingSX}>
        <Box component='h4' >Start Your Medical Insurance Billing With Us Today!</Box>
        <Box component='p'>As a nationally recognized medical billing company, My MD Solutions can make a difference in your practice. Alleviate the headache of medical insurance billing collections and allow our team of our specialists to handle your practice’s claims.</Box>
      </ContentContainer>

      <SiteFooter/>

    </Box>
  );

}