import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { home, bannerImage } from './styles';

import { SiteHeader } from '../../components/layouts';
import banner from '../../assets/img/banner_1366x512.png';
import ServiceIconGrid from '../../components/layouts/ServiceIconGrid';
import ContentContainer from '../../components/layouts/ContentContainer';
import SiteFooter from '../../components/layouts/SiteFooter';
import { Button } from '../../components/inputs';

import ContactForm from '../../components/forms/ContactForm';

export default () => {

  return (
    <Box
      component='div'
      sx={home}
    >
      <SiteHeader />

      <Box component='span' sx={bannerImage} >
        <img src={banner} alt="Happy Doctors" />
      </Box>

      <ServiceIconGrid/>

      <ContentContainer containerSX={{ bgcolor: 'grey.50', padding: '80px 0px'}} wrapperSX={{ width: { xs: 1/3 }}}>
        <Box
          component='h3'
          sx={{ textAlign: 'center', color: 'primary.main'}}
        >
          My MD Solutions is the modern doctor’s profitable practice solution to many of today’s challenges. My MD Solutions is dedicated to helping medical practices become better businesses.
        </Box>
        <Box
          component='h3'
          sx={{
            textAlign: 'center',
            color: 'primary.main',
            marginTop: '50px',
          }}
        >
          All products and services are designed to increase practice profitability, improve clinical results, and bolster patient satisfaction. We tackle the tough issues like denied reimbursement claims and keeping patients on protocol.
        </Box>
        <Box
          component='h3'
          sx={{ textAlign: 'center', color: 'primary.main', marginTop: '50px'}}
        >
          Our team of experts is here to lend a trustworthy hand with financial and clinical services.
        </Box>

        <Box component='div' sx={{ textAlign: 'center', marginTop: '80px'}}>
          <Link to='/contact' style={{ textDecoration: 'none' }}>
            <Button>Get More Information</Button>
          </Link>
        </Box>
      </ContentContainer>

      <SiteFooter/>
    </Box>
  );
}