const contentContainer = {
  display: 'flex',
  width: 1/1,
  justifyContent: 'center',
};

const contentWrapper = {
  display: 'block',
  width: {
    xs: 1/1,
    md: 4/5,
    lg: 2/3,
  }
};

module.exports = {
  contentContainer,
  contentWrapper
};