import React from 'react';
import {
  siteHeader,
  headerContent,
  siteHeaderLink,
  siteHeaderNav,
  contactBanner,
} from './styles';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';

import ContentContainer from '../ContentContainer';
import Logo from '../../logo';

const SiteHeaderLink = ({ LinkProps, children }) => (
  <Box
    component='div'
    sx={siteHeaderLink}
  >
    <Link {...LinkProps}>
      {children}
    </Link>
  </Box>
);

export default () => {
  return (
    <Box
      component='header'
      sx={siteHeader}
    >
      <Box
        component='div'
        sx={contactBanner}
      >
        <ContentContainer>
          <Box component='p'>
            Call Today For A Consultation: 1-800-987-6543
          </Box>
        </ContentContainer>
      </Box>

      <ContentContainer>
        <Box
          component='div'
          sx={headerContent}
        >
          <Logo />

          <Box
            component='nav'
            sx={siteHeaderNav}
          >
            <SiteHeaderLink LinkProps={{ to: '/' }} >Home</SiteHeaderLink>
            <SiteHeaderLink LinkProps={{ to: '/about'}} >About</SiteHeaderLink>
            {/*<SiteHeaderLink LinkProps={{ to: '/consulting'}} >Consulting</SiteHeaderLink>*/}
            <SiteHeaderLink LinkProps={{ to: '/services'}} >Services</SiteHeaderLink>
            <SiteHeaderLink LinkProps={{ to: '/billing_solutions'}} >Billing Solutions</SiteHeaderLink>
            <SiteHeaderLink LinkProps={{ to: '/contact'}} >Contact</SiteHeaderLink>
          </Box>
        </Box>
      </ContentContainer>
    </Box>
  )
};