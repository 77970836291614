import React from 'react';
import Box from '@mui/material/Box';
import SiteHeader from '../../components/layouts/SiteHeader';
import SiteFooter from '../../components/layouts/SiteFooter';
import ContentContainer from '../../components/layouts/ContentContainer';

import { services } from './styles';


export default (props) => {
  const containerPaddingSX={
    padding: '50px 0px',
  };
  const headerSX = { color: 'primary.main' };
  const textSX = { typography: 'subtitle1', color:'grey.600' };
  const containeWrappingSX = {
    '& > p': textSX,
    '& > ul': textSX,
    '& > h3': headerSX,
    '& > h4': headerSX,
  };
  const containerGreySX = {backgroundColor: 'grey.100'};
  // const textSX = { typography: 'subtitle1', color:'grey.600'};

  return (
    <Box
      component='div'
      sx={services}
    >
      <SiteHeader />

      <ContentContainer containerSX={{...containerPaddingSX, ...containerGreySX}} wrapperSX={containeWrappingSX} id='toxicology'>
        <Box component='h3' >Toxicology</Box>
        <Box component='p' >We enable doctors to monitor patients and reduce liability. By providing key information we make it easier than ever to better manage the treatment of your patients.</Box>
        <Box component='p' >Generate additional revenue with no out-of-pocket costs or risks. We screen patients for you from start to finish.</Box>
        <Box component='p' >We are toxicology testing experts that provide everything you need to implement a successful patient monitoring program and not disrupt your current practice protocol.</Box>
      </ContentContainer>

      <ContentContainer containerSX={containerPaddingSX} wrapperSX={containeWrappingSX} id='pharmaceutical-compounds' >
        <Box component='h3' >Pharmaceutical Compounds</Box>
        <Box component='p' >We provide a better way to meet the unique needs of individual patients.</Box>
        <Box component='p' >When patients are prescribed a generic or name-brand drug, the dosage and form are often very limited. The ability to compound medications gives the patient a much wider scope of prescription medication strengths and forms, and offers a variety of clinical advantages:</Box>
        <Box component='ul' >
          <Box component='li' >If a patient cannot take oral pain medication, we offer a compounded prescription strength topical pain cream.</Box>
          <Box component='li' >If a patient is allergic to aspirin, we offer a compounded medication without aspirin.</Box>
          <Box component='li' >If a medication is too strong for a patient, we offer a compounded prescription at lower dosages.</Box>
        </Box>
      </ContentContainer>

      <ContentContainer containerSX={{...containerPaddingSX, ...containerGreySX}} wrapperSX={containeWrappingSX}>
        <Box component='h3' >Medical Marketing</Box>
        <Box component='p' >In today’s digital marketplace, potential patients are searching online to the find the physician, practice, or medical specialist that is right for them. Will patients find your practice, or will they stumble upon your competition?</Box>
        <Box component='p' >With strategic medical marketing plans customized for the unique needs of your practice, My MD Solutions will help you and your staff create the right online strategy that will attract new patients. We are experts in online medical marketing strategies which includes search engine optimization (SEO), search engine marketing (SEM), and branding techniques. My MD Solutions can also create or redesign your current website.  If your website does not offer a backend tool that will allow you to track who, what, when, and where your online traffic is coming from you are missing the mark by today’s standards.</Box>
      </ContentContainer>

      <ContentContainer containerSX={containerPaddingSX} wrapperSX={containeWrappingSX}>
        <Box component='h3' >Attract New Patients with Localized Marketing Strategies</Box>
        <Box component='p' >With the help of Local Marketing, Inc., a full-service digital marketing firm, the Medical Consultants Group,</Box>
        <Box component='p' >My MD Solutions will create customized campaigns and unique content that will place your practice in front of potential patients.  As experts in grass roots marketing, we understand and know how to attract those patients that go to the web before deciding on a new doctor in their area.  Quality, localized content and targeted SEO and SEM campaigns are essential to converting website visitors into new patients. Our customized medical marketing strategies provide practices with the resources they need to connect with new patients and establish better conversion ratio.</Box>
      </ContentContainer>

      <ContentContainer containerSX={{...containerPaddingSX, ...containerGreySX}} wrapperSX={containeWrappingSX}>
        <Box component='h3' >How Can My MD Solutions Help Your Practice?</Box>
        <Box component='p' >Drawing on a variety of online medical marketing tools and resources, My MD Solutions can help grow your practice with a customized set of services:</Box>
        <Box component='h4' >SEO</Box>
        <Box component='p' >Improve your practice’s online visibility through extensive keyword research and proven organic search techniques. Rank higher in search engines with enhanced tagging and linking.</Box>
        <Box component='h4' >SEM</Box>
        <Box component='p'  >Optimize your medical website and create unique and compelling ads that perform better and gain visibility in search engine results.</Box>
        <Box component='h4' >Social Media Marketing</Box>
        <Box component='p'  >Interact with your existing patients and bring in new traffic with tailored social media content and targeted updates.</Box>
        <Box component='h4' >Website Design and Development</Box>
        <Box component='p'  >Attract new patients with an attractive, user-friendly website complete with keyword rich content.</Box>
      </ContentContainer>

      <ContentContainer containerSX={containerPaddingSX} wrapperSX={containeWrappingSX}>
        <Box component='h3' >Successful Patient Accounts Management</Box>
        <Box component='p' >As a medical billing company, you make more money as you collect more for your practices. Ultimately, your success is tied to your ability to collect quickly and profitably.</Box>
        <Box component='p' >With today’s higher patient deductibles, up to 30% of practice revenue can come from patient balances. Sending out statements to collect this money is time consuming, costly, and, based on national averages, only successful 40% of the time. You need a better strategy for collecting patient balances.</Box>
        <Box component='h4' >Revenue Cycle Management for Independent Practices</Box>
        <Box component='p' >The good news for medical billing companies is there is a better way to collect patient balances—and it doesn’t include spending valuable time and resources on phone calls and patient invoices.</Box>
        <Box component='p' >The system you can provide will enable the practice to keep each patient’s credit card information on file in a HIPAA and PCI secure data vault. When the patient is seen, this system will allow the practice to query the patient’s insurance company and predict the amount the patient will owe, over and above their co-pay right there at the time of the visit.</Box>
        <Box component='p' >It’s that simple—you and the practice make more money, and your medical billing company cuts costs and creates another source of revenue.</Box>
      </ContentContainer>

      <SiteFooter/>

    </Box>
  );

}